// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
function getBaseUrl() {
  const domain = window.location.hostname;

  if(domain.includes('kohtao')) {
    return 'https://api.kohtao.qrmais.app';
    // const apiURL = `https://api.${domain}`;
  }

  return 'https://api.giardino.qrmais.app';
  // return apiURL;
}

export const environment = {
  production: false,
  // defaultauth: 'https://api.giardino.qrmais.app/api/v1',
  // defaultImgs: 'https://api.giardino.qrmais.app/',
  defaultauth: getBaseUrl() + `/api/v1`,
  defaultImgs: getBaseUrl(),
  // defaultauth: 'http://localhost/api/v1',
  // defaultImgs: 'http://localhost',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
