import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service'; // Certifique-se de importar o serviço Loader aqui

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
      private authenticationService: AuthenticationService,
      private loaderService: LoaderService // Injete o serviço de carregamento aqui
  ) {}

  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.startLoading();

    const currentUser = this.authenticationService.currentUsers();

    if (currentUser && (currentUser.access_token || currentUser.token)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token ?? currentUser.token}`,
        },
      });
    }

    return next.handle(request).pipe(
        finalize(() => {
          this.loaderService.stopLoading();
        })
    );
  }
}
