import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClientModel } from "../../pages/clients/model/client.model";

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    /**
     * Returns the current user
     */
    public currentUsers(): User {
        let user: User;
        this.currentUser.subscribe((currentUser: User) => {
            user = currentUser;
        });
        return user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*' // Isso pode ser necessário, mas a eficácia depende das configurações do servidor
                // Outros cabeçalhos, se necessário
            }
        }
        return this.http.post<any>(environment.defaultauth + `/auth/login`, { email, password }, options) 
            .pipe(map(data => {
                // login successful if there's a jwt token in the response
                if (data.user && data.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify({ expires: data.expires_in, token: data.access_token }));
                    localStorage.setItem('username', JSON.stringify(data.user.name));

                    const currentUser = {
                        tokenAccess: data.access_token,
                        username: data.user.name,
                        useremail: data.user.email,
                        userid: data.user.id
                    };

                    localStorage.setItem('user', JSON.stringify(currentUser));

                    this.currentUserSubject.next(data);
                }

                return data;
            }));
    }

    /**
     * Logout the user
     */
    logout() {
        this.http.post<any>(`${environment.defaultauth}/auth/logout`, null).subscribe(() => {
            localStorage.removeItem('currentUser');
            this.currentUserSubject.next(null);
        });
    }

    me(token) {
        return this.http.get<any>(`${environment.defaultauth}/auth/me`);
    }

    updateMe(userData: any): Observable<any> {
        return this.http.post(`${environment.defaultauth}/auth/me`, userData);
    }


    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            return response;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            return response.data;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    forgotPassword(email: string) {
        return this.http.post<any>(`${environment.defaultauth}/auth/forgot-password`, {
            email: email
        })
    }
}

