import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bx-line-chart',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.DASHBOARD.TITLE',
                link: '/admin/analitico',
                parentId: 2,
                disablePayment: false
            },
        ],
        disablePayment: false,
    },
    {
        id: 8,
        isLayout: true
    },
    {
        id: 22,
        label: 'MENUITEMS.TABLE.TEXT',
        icon: 'bx-table',
        subItems: [
            {
                id: 23,
                label: 'MENUITEMS.TABLE.TABLE_TITLE',
                link: '/admin/table/list',
                parentId: 22,
                disablePayment: false
            }
        ],
        disablePayment: false
    },
    {
        id: 23,
        label: 'MENUITEMS.ORDERS.TEXT',
        icon: 'bx-bell',
        subItems: [
            {
                id: 2,
                label: 'MENUITEMS.ORDERS.KDS.TITLE',
                link: '/admin/orders/kds',
                parentId: 23,
                disablePayment: false
            },
            {
                id: 3,
                label: 'MENUITEMS.ORDERS.CHECKOUTS',
                link: '/admin/orders/list',
                parentId: 24,
                disablePayment: false
            },
            {
                id: 4,
                label: 'MENUITEMS.ORDERS.CART_ITEMS',
                link: '/admin/cart_items/list',
                parentId: 24,
                disablePayment: false
            }
        ],
        disablePayment: false
    },
    {
        id: 22,
        label: 'MENUITEMS.PROMOTION_TICKET.TEXT',
        icon: 'bx-bookmarks',
        subItems: [
            {
                id: 23,
                label: 'MENUITEMS.PROMOTION_TICKET.TICKET_TITLE',
                link: '/admin/cupons/list',
                parentId: 22,
                disablePayment: false
            }
        ],
        disablePayment: false
    },
    {
        id: 22,
        label: 'MENUITEMS.STAFF.TEXT',
        icon: 'bx-male',
        subItems: [
            {
                id: 23,
                label: 'MENUITEMS.STAFF.TICKET_TITLE',
                link: '/admin/equipe/list',
                parentId: 22,
                disablePayment: false
            }
        ],
        disablePayment: false
    },
    // {
    //     id: 23,
    //     label: 'MENUITEMS.CLIENTE_MANAGEMENT.TEXT',
    //     icon: 'bx-message-rounded-dots',
    //     subItems: [
    //         {
    //             id: 24,
    //             label: 'MENUITEMS.CLIENTE_MANAGEMENT.TICKET_TITLE',
    //             link: '/admin/clientes/list',
    //             parentId: 23,
    //             disablePayment: false
    //         }
    //     ],
    //     disablePayment: false
    // }, 
    {
        id: 27,
        label: 'MENUITEMS.MENU_MANAGEMENT.TEXT',
        icon: 'bx-food-menu',
        subItems: [
            {
                id: 28,
                label: 'MENUITEMS.CATEGORIE_MANAGEMENT.TICKET_TITLE',
                link: '/admin/categorias/list',
                parentId: 27,
                disablePayment: false
            },
            {
                id: 29,
                label: 'MENUITEMS.ITEM_MANAGEMENT.TICKET_TITLE',
                link: '/admin/items/list',
                parentId: 27,
                disablePayment: false
            }
        ],
        disablePayment: false
    },
    {
        id: 27,
        label: 'MENUITEMS.BANNERS.TEXT',
        icon: 'bx-image-add',
        subItems: [
            {
                id: 28,
                label: 'MENUITEMS.BANNERS.TEXT_TITLE',
                link: '/admin/banners',
                parentId: 27,
                disablePayment: false
            },
        ],
        disablePayment: false
    },
    // {
    //     id: 27,
    //     label: 'MENUITEMS.EVENTS.TEXT',
    //     icon: 'bx-calendar-event',
    //     subItems: [
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.EVENTS.TEXT_TITLE',
    //             link: '/admin/events',
    //             parentId: 29,
    //             disablePayment: false
    //         }
    //     ],
    //     disablePayment: false
    // },
    // {
    //     id: 26,
    //     label: 'MENUITEMS.SUGESTION_MANAGEMENT.TEXT',
    //     icon: 'bx-message-dots',
    //     subItems: [
    //         {
    //             id: 25,
    //             label: 'MENUITEMS.SUGESTION_MANAGEMENT.TICKET_TITLE',
    //             link: '/admin/sugestoes/list',
    //             parentId: 24,
    //             disablePayment: false
    //         }
    //     ],
    //     disablePayment: false
    // },
    {
        id: 55,
        label: 'MENUITEMS.EMAIL.TEXT',
        icon: 'bx-envelope',
        badge: {
            variant: 'success',
            text: 'MENUITEMS.EMAIL.NEW'
        },
        subItems: [
            {
                id: 56,
                label: 'MENUITEMS.EMAIL.EMAIL_TITLE',
                link: '/admin/',
                parentId: 57,
                disablePayment: true
            },            {
                id: 56,
                label: 'MENUITEMS.EMAIL.EMAIL_TITLE_SMS',
                link: '/admin/',
                parentId: 57,
                disablePayment: true
            },
            {
                id: 5,
                label: 'MENUITEMS.EMAIL.TEMPLATE',
                parentId: 59,
                disablePayment: true
            },
            {
                id: 5,
                label: 'MENUITEMS.EMAIL.ADS',
                parentId: 59,
                disablePayment: true
            }
        ],
        disablePayment: false
    },
    {
        id: 26,
        label: 'MENUITEMS.RESTAURANT_SETTINGS.ESTABLISHMENT',
        icon: 'bx bxs-cog bx-tada',
        subItems: [
            {
                id: 25,
                label: 'MENUITEMS.RESTAURANT_SETTINGS.SETTINGS',
                link: '/admin/establishment/configuration',
                parentId: 24,
                disablePayment: false
            }
        ],
        disablePayment: false
    }
];

